<template>
    <div class="body">
        <!--导航栏--->
        <section class="top">
            <van-nav-bar title="交易成功" left-arrow :fixed='isTop' @click-left="$router.back(-1)" />
        </section>
        <section class="successInfo">
            <div class="successIcon">
                <img src="../../assets/img/confirm01.png" alt="图片加载失败">
            </div>
            <div class="successText">
                <p>交易成功</p>
            </div>
            <!-- <div class="getIntegral">共赠{{ integral }}官凭，已到账</div> -->
        </section>
        <section class="bottom">
            <!-- to后面接跳转路径 -->
            <van-button round hairline plain color="#333333" type="info" @click="checkNow">立即查看</van-button>
            <van-button round color="#E54320" type="info" @click="backIndex">返回首页</van-button>
        </section>
    </div>
</template>

<script>
// import { Toast } from 'vant';

export default {
    data() {
        return {
            isTop: true,//导航栏永远置顶
            integral: 0,//获赠积分
        }
    },
    created() {
        // console.log(this.$route.query);
        this.integral = this.$route.query.data
    },
    methods: {
        //立即查看
        checkNow() {
            //跳转到完成列表
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                // Toast('立即查看')
                window.webkit.messageHandlers.flMallEnterConfirmedList.postMessage("");
            }
            if (isAndroid) {
                window.android.flMallEnterConfirmedList();
            }
        },
        //返回首页
        backIndex() {
            //跳转到app首页
            // console.log('返回首页');
            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                // Toast('去首页')
                window.webkit.messageHandlers.returnHome.postMessage("up");
            }
            if (isAndroid) {
                window.android.close();
            }
        }
    },
    mounted() {
        //样式适配
        let screenWidth = document.documentElement.getBoundingClientRect().width
        document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
    }
}
</script>

<style lang="less" scoped>
.body {
    width: 100%;
    height: 100%;

    .top {
        height: 6%;
    }

    .successInfo {
        padding: 1.7297rem 0.4324rem 0.4324rem 0.4324rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: baseline;
        align-items: center;

        .successIcon {
            padding: 0.8649rem 0;


            img {

                width: 1.8919rem;
            }
        }

        .successText {
            line-height: 1.2973rem;
            font-size: 0.4324rem;
        }

        .getIntegral {
            color: #999999;
            font-size: 0.4324rem;
        }
    }

    .bottom {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        height: 20%;
        padding: 0.4324rem 0.5189rem;
    }
}
</style>